<form data-cy="assays-form" #form="ngForm" luxLayout="column" luxFill>
  <div
    [ngClass]="[assayWrapperPresentationReady ? 'presentationReady' : '']"
    [hidden]="!assayWrapperPresentationReady"
    id="accession-wrapper"
    luxLayout="row"
    luxFlex
    luxFill>
    <app-assay-details
      *ngIf="assays?.length > 0 || observationAssays?.length > 0"
      #assayDetails
      [assayCardHasChanges]="currentAssayWrapper?.presentationClass?.indexOf('HAS_CHANGES') > -1"
      [lab]="lab"
      [panels]="panels"
      [headerAccession]="headerAccession"
      [workspaceAccession]="accession"
      (keydown.escape)="handleDetailsEscape($event)"
      (markAsNoResult)="handleNoResult()"
      (selectedAssayUpdated)="handleAssayUpdated()"
      (panelCommentsUpdated)="handlePanelCommentsUpdated($event)"
      (panelAcceptUpdated)="handlePanelAcceptUpdated()"
      (focusOut)="handleDetailsEscape($event)"></app-assay-details>
    <!-- LINE-ITEM VIEW -->
    <div luxLayout="column" luxFlex>
      <fieldset luxFlex luxLayout="column" data-cy="assays-fieldset" [disabled]="appStateService.loading">
        <div
          *ngIf="assays?.length > 0 || observationAssays?.length > 0"
          luxLayout="row"
          data-cy="line-item-controls-wrapper"
          luxLayoutAlign="space-between end"
          id="workspaceAccessionControlsWrapper">
          <app-toolbar (handleNextInQueueClick)="nextInQueue()" [hasChanges]="saveButtonEnabled"></app-toolbar>
          <div luxLayout="row" luxLayoutGap="13px">
            <lux-lab-notes
              #labNotes
              *luxIfFeature="'LAB_NOTES'"
              [getLabNotesLink]="appStateService.getLabNotesLink"
              [addLabNoteLink]="appStateService.addLabNoteLink"
              [accessionId]="accession.accessionId"
              dragBoundary="#accession-wrapper"
              [lab]="lab"
              [context]="'CBC'"
              [labNotesPosition]="{ x: -368, y: 35 }"></lux-lab-notes>

            <button
              type="button"
              data-cy="accept-button"
              class="spot-button spot-button--primary"
              title="{{ 'LABELS.ACCEPT_ALL' | translate }}"
              (click)="saveAssays(true)"
              [disabled]="(labNotes?.hasUnsavedLabNotes$ | async) || !acceptButtonEnabled">
              {{ 'LABELS.ACCEPT_ALL' | translate }}
            </button>
            <!--            async pipe should be on the left side of Logical OR expression because it's evaluated left to right
                            if it will be on the right, then async pipe will be returning null on acceptButtonEnabled change until hasUnsavedLabNotes$ will trigger new value
                             this happens because labNotes is not available when template rendering and on async pipe first run it returns null.
                             Another solution is to make hasUnsavedLabNotes$ a BehaviorSubject that will have a value regardless of labNotes availability-->
            <button
              type="button"
              data-cy="save-button"
              class="spot-button spot-button--secondary"
              title="{{ 'LABELS.SAVE' | translate }}"
              [disabled]="(labNotes?.hasUnsavedLabNotes$ | async) || !saveButtonEnabled"
              (click)="saveAssays()">
              {{ 'LABELS.SAVE' | translate }}
            </button>
            <button
              type="button"
              data-cy="workspace-queue-next"
              title="{{ 'LABELS.SKIP' | translate }}"
              *ngIf="appStateService.queueWorkspace"
              (click)="nextInQueue()"
              [disabled]="labNotes?.hasUnsavedLabNotes$ | async"
              class="spot-button next-link spot-button--secondary spot-button--icon-only spot-button--with-icon">
              <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                <title>{{ 'LABELS.SKIP' | translate }}</title>
                <use xlink:href="../../assets/icons/spot_icons.svg#back"></use>
              </svg>
            </button>
          </div>
        </div>

        <div luxLayout="row" luxFlexOffset="20px" *ngIf="assays?.length > 0">
          <div luxLayout="column" luxFlex="50%" data-cy="non-cell-line-assays" class="non-cell-line-assays">
            <ng-container *ngIf="!nonCellLineAssays || nonCellLineAssays.length < 1">
              <div class="line-item-assays-placeholder" luxFlex></div>
            </ng-container>
            <ng-container
              *ngFor="let assay of nonCellLineAssays; let first = first; let last = last; let index = index">
              <app-assay-line-item
                #assayWrapper
                (valueChange)="handleValueChange()"
                [lab]="lab"
                [assay]="assay"
                [firstCard]="first"
                [lastCard]="last">
              </app-assay-line-item>
            </ng-container>
          </div>
          <div luxLayout="column" luxFlex="50%" data-cy="cell-line-assays" class="cell-line-assays">
            <ng-container *ngIf="!cellLineAssays || cellLineAssays.length < 1">
              <div class="line-item-assays-placeholder"></div>
            </ng-container>
            <ng-container *ngFor="let assay of cellLineAssays; let first = first; let last = last; let index = index">
              <app-assay-line-item
                #assayWrapper
                (valueChange)="handleValueChange()"
                [lab]="lab"
                [assay]="assay"
                [absoluteAssay]="getAbsoluteAssay(assay)"
                [firstCard]="first"
                [lastCard]="last">
              </app-assay-line-item>
            </ng-container>
          </div>
        </div>

        <div
          luxLayout="row wrap"
          luxFlexOffset="30px"
          luxFlex
          *ngIf="observationAssays?.length > 0"
          data-cy="observation-assays">
          <ng-container *ngFor="let assay of observationAssays; let first = first; let last = last; let index = index">
            <app-assay-card
              #assayWrapper
              (valueChange)="handleValueChange()"
              [lab]="lab"
              [assay]="assay"
              [firstCard]="first"
              [lastCard]="last">
            </app-assay-card>
          </ng-container>
        </div>
      </fieldset>
    </div>
    <!-- /LINE-ITEM VIEW -->
  </div>
</form>
